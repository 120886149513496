"use client";

import Image from "next/image";
import Link from "next/link";
import React from "react";
import { useTranslation } from "react-i18next";
import { BiCar, BiHeart, BiHome, BiMoneyWithdraw } from "react-icons/bi";
import { FaBalanceScale, FaUmbrellaBeach } from "react-icons/fa";

const Section5 = () => {
  const { t } = useTranslation();

  const services: Array<{
    href: string;
    icons: React.ReactNode;
    title: string;
  }> = [
    {
      href: "/services/assurance-voiture",
      icons: (
        <div className="flex-shrink-0">
          <BiCar size={30} />
        </div>
      ),
      title: t("Home.Section1.key4"),
    },
    {
      href: "/services/assurance-sante",
      icons: (
        <div className="flex-shrink-0">
          <BiHeart size={30} />
        </div>
      ),
      title: t("Home.Section1.key5"),
    },
    {
      href: "/services/protection-juridique",
      icons: (
        <div className="flex-shrink-0">
          <FaBalanceScale size={30} />
        </div>
      ),
      title: t("Home.Section1.key6"),
    },
    {
      href: "/services/prevoyance",
      icons: (
        <div className="flex-shrink-0">
          <FaUmbrellaBeach size={30} />
        </div>
      ),
      title: t("Home.Section1.key7"),
    },
    {
      href: "/services/assurance-menage",
      icons: (
        <div className="flex-shrink-0">
          <BiHome size={30} />
        </div>
      ),
      title: t("Home.Section1.key8"),
    },
    {
      href: "/services/hypotheque",
      icons: (
        <div className="flex-shrink-0">
          <BiMoneyWithdraw size={30} />
        </div>
      ),
      title: t("Home.Section1.key9"),
    },
  ];

  return (
    <div
      className="sec-5 relative overflow-hidden pb-14 pt-14 md:pb-14"
      id="services-area"
    >
      <div className="absolute -top-1/2 -z-10 h-full w-full rounded-full theme-bg-color opacity-30 blur-[600px]"></div>
      <div className="mx-auto flex max-w-6xl flex-col justify-center px-4">
        <h2 className="text-[32px] font-bold leading-9 md:text-[34px]">
          {t("Home.Section5.key1")}
        </h2>
        <p className="mb-7 text-base leading-6">{t("Home.Section5.key2")}</p>
        <div className="mb-[28px] grid grid-cols-[repeat(auto-fill,minmax(150px,1fr))] gap-[14px]">
          {services.map((service) => (
            <Link
              key={service.href}
              rel="next"
              className="card-shadow flex h-[136px] w-[160px] flex-col items-center justify-center gap-2.5 rounded-xl theme-bg-color text-white transition-all hover:scale-105 hover:bg-sky-700 hover:shadow-xl"
              href={service.href}
            >
              {service.icons}
              <span className="text-center text-[14px] font-medium">
                {service.title}
              </span>
            </Link>
          ))}
        </div>
      </div>
      <div className="md:hidden">
        <div className="flex flex-col items-center gap-[26px] mt-3 ">
          <div className="flex flex-col items-center">
            <div className="min-h-[255px] bg-white w-full flex justify-center items-center">
              <img
                alt="Made in Switzerland"
                width="255"
                height="300"
                className="h-[300px] w-[255px] object-cover"
                src="https://cdn.1cdn.app/application/CLEARASSURE/25021311192286_made_in_switzerland.png"
              />
            </div>
            <div className="flex flex-col items-center rounded-b-xl bg-white px-[52px] py-6 shadow-md shadow-[rgba(44,48,77,0.05)]">
              <div className="mb-1.5 flex items-center">
                <span className="mr-1 text-[28px] font-bold leading-normal">
                  5
                </span>
                {[1, 2, 3, 4, 5].map((_, index) => (
                  <Image
                    key={index}
                    alt="icon"
                    width="28"
                    height="28"
                    src="https://cdn.1cdn.app/application/CLEARASSURE/25021312552491_star.svg"
                  />
                ))}
              </div>
              <span className="text-base font-medium leading-normal">
                {t("Home.Section6.key8")}{" "}
                <strong>{t("Home.Section6.key9")}</strong>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section5;
